
import { Header } from "../../Components/Header";
import { WhoAreWe } from "../../Components/WhoAreWe"
import { Services } from "../../Components/Services";
import { Videos } from "../../Components/Videos";
import { Quote } from "../../Components/Quote";
import { Testimonials } from "../../Components/Testimonials";
import "./Home.css"; 

const Home = (props) => {
  console.log(props)
    return (
      <div>
      <Header data={props.data.Header} />
      <WhoAreWe data={props.data.WhoAreWe} />
      <Videos data={props.data.Videos} />
      <Quote data={props.data.Quote4} />
      <Services data={props.data.Services} />
      <Quote data={props.data.Quote2} />
      <Testimonials data={props.data.Testimonials} /> 
      </div>
    )
  }

  
  export default Home;
  