import { Outlet } from "react-router-dom";
import { Navigation } from "../Components/shared/navigation";
import NavBar from "../Components/shared/NavBar";
import { Contact } from "../Components/shared/contact";

const MainLayout = (props) => {
  return (
    <>
        <NavBar />
        <Outlet />
        <Contact data={props.data.Contact} />
    </>
  )
};

export default MainLayout;