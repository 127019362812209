import { Link } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

export const WhoAreWe = (props) => {
  return (
    <div id="about">
      <Container>
        <Row>
          <Col md={6}>
            <AnimationOnScroll
              animateIn="animate__fadeIn"
              animateOnce="true"
              offset="200"
            >
              <div className="about-text">
                <h2>LAST COACH YOU WILL EVER NEED!</h2>
                <p style={{ whiteSpace: "pre-wrap" }}>
                  {props.data ? props.data.paragraph : "loading..."}
                </p>
                <h3>Why Choose Us?</h3>
                <Row className="list-style">
                  <Col md={6}>
                    <ul>
                      {props.data
                        ? props.data.Why.map((d, i) => (
                            <li key={`${d}-${i}`}>{d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </Col>
                  <Col md={6}>
                    <ul>
                      {props.data
                        ? props.data.Why2.map((d, i) => (
                            <li key={`${d}-${i}`}> {d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </Col>
                </Row>
              </div>
            </AnimationOnScroll>
          </Col>
          <Col md={{ span: 5, offset: 1 }}>
            <Image src="img/Miguel.jpeg" rounded style={{ maxWidth: "100%" }} />
          </Col>
        </Row>
        <div className="action-btn text-center">
          <Link to="/about" className="btn btn-primary btn-lg">
            Learn More!
          </Link>
        </div>
      </Container>
    </div>
  );
};
