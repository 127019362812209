import { AnimationOnScroll } from "react-animation-on-scroll";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <Container>
        <AnimationOnScroll animateIn="animate__slideInUp" animateOnce="true">
          <Row>
            <Col md={{ span: 8, offset: 2 }} className="section-title">
              <h2>About Us</h2>
              <p style={{ whiteSpace: "pre-wrap", paddingBottom: "40px" }}>
                {props.data ? props.data.bio : "Loading"}
              </p>
            </Col>
          </Row>
        </AnimationOnScroll>
        <Row>
          {props.data
            ? props.data.members.map((d, i) => (
                <div key={`${d.firstName}-${i}`} className="col-md-6 col-md-offset-3">
                  <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
                    <h2>{"Meet " + d.firstName}</h2>
                    <div className="thumbnail">
                      <Image src={d.img} alt="..." className="team-img" />
                      <div className="caption">
                        <h4>{d.firstName + " " + d.lastName}</h4>
                        <p>{d.job}</p>
                        <p>{d.bio}</p>
                      </div>
                    </div>
                    <hr></hr>
                    <br></br>
                  </AnimationOnScroll>
                </div>
              ))
            : "loading"}
        </Row>
      </Container>
    </div>
  );
};
