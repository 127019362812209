import firebase from "firebase";

var firebaseApp = firebase.initializeApp({
  // Your firebase credentials
  apiKey: "AIzaSyBuX8-2ibilZoNCbFkJhrdZo4WJsg8uXQ4",
  authDomain: "hardworkdiscipline-d8e44.firebaseapp.com",
  projectId: "hardworkdiscipline-d8e44",
  storageBucket: "hardworkdiscipline-d8e44.appspot.com",
  messagingSenderId: "295925370905",
  appId: "1:295925370905:web:8d82077b6f1b89c1179a88",
  measurementId: "G-FBWGC2NDML"
});

var db = firebaseApp.firestore();

export { db }; 