import { AnimationOnScroll } from "react-animation-on-scroll";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

export const Quote = (props) => {
  return (
    <div id="quote">
      <Container>
        <Row className="list-style">
          <Col md={6}>
            <Image
              src={props.data ? props.data.img : ""}
              rounded
              style={{ maxWidth: "100%" }}
            />
          </Col>
          <Col md={6}>
            <AnimationOnScroll
              animateIn="animate__slideInUp"
              animateOnce="true"
            >
              <div className="quote-text text-center">
                <img src="img/quotes/quotes.svg" className="quotes" alt="" />
                <h2>{props.data ? props.data.quote : "loading..."}</h2>
                <h3>{props.data ? props.data.author : "loading..."}</h3>
              </div>
            </AnimationOnScroll>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
