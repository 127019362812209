import "./App.css"; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from "react";
import JsonData from "./Data/data.json";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./Layouts/MainLayout";
import Home from "./Pages/Home/Home";
import About from "./Pages/AboutUs/AboutUs";
import ServicesPage from "./Pages/OurValue/OurValue";
import NoPage from "./Pages/404/404";
import ScrollToTop from "./Hooks/ScrollToTop";

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  const routes = [
    { path: '/', name: 'Home', Component: Home },
    { path: '/about', name: 'About', Component: About },
    { path: '/services', name: 'Services', Component: ServicesPage },
    { path: '*', name: 'NoPage', Component: NoPage }
  ]
  
  return (
    <Router>
      <ScrollToTop>
      <Routes>
      <Route path="/" element={<Layout data={landingPageData}/>}>
          <Route index element={<Home data={landingPageData}/>} />
          <Route path="about" element={<About data={landingPageData}/>} />
          <Route path="services" element={<ServicesPage data={landingPageData}/>} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
      </ScrollToTop>
    </Router>
  )
}
export default App;
