import { HeaderGeneral } from "../../Components/HeaderGeneral";
import { Gallery } from "./../../Components/Gallery";
import { Team } from "./../../Components/Team";
import "./AboutUs.css";

const AboutUs = (props) => {
  return (
    <div>
      <HeaderGeneral data={props.data.HeaderAboutUs} />
      <Team data={props.data.Team} />
      <Gallery data={props.data.TeamGallery} />
    </div>
  );
};

export default AboutUs;
