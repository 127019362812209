import { AnimationOnScroll } from "react-animation-on-scroll";
import { Parallax } from "react-parallax";
const image1 = "../img/Pattern.png";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <Parallax bgImage={image1} strength={-75} blur={5}>
        <div className="container">
          <div className="section-title">
            <h2>Our Services</h2>
          </div>
          <div className="row equal">
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className="col-md-4">
                    <AnimationOnScroll
                      animateIn="animate__fadeInRight"
                      animateOnce="true"
                    >
                      <i className={d.icon}></i>
                      <div className="service-desc">
                        <h3>{d.name}</h3>
                        <p>{d.text}</p>
                      </div>
                    </AnimationOnScroll>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </Parallax>
    </div>
  );
};
