// import { AnimationOnScroll } from "react-animation-on-scroll";
// import { Parallax } from "react-parallax";
import { Carousel } from "react-bootstrap";
import ReactPlayer from "react-player/lazy";

export const Videos = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Miguel Chavez</h2>
        </div>
        <Carousel>
        {props.data
          ? props.data.map((videoObj, i) => (
            <Carousel.Item key={videoObj.id}>
                <div className="video-wrapper">
                  <ReactPlayer
                    width="100%"
                    height="100%"
                    url={videoObj.src}
                    pip={true}
                    controls={true}
                    playing={true}
                  />
                </div>
              </Carousel.Item>
            ))
          : "loading"}
          </Carousel>
      </div>
    </div>
  );
};

{
  /* 
            <Carousel><Carousel.Item key={videoObj.id}>
                    <ReactPlayer
                      url={videoObj.src}
                      pip={true}
                      controls={true}
                      playing={true}
                    />
                    <Carousel.Caption>
                      <h3>{videoObj.title}</h3>
                      <p>{videoObj.credit}</p>
                    </Carousel.Caption>
                  </Carousel.Item> */
}
