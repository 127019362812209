import { Parallax } from "react-parallax";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

const image1 = "../img/Header.png";
export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro d-block d-sm-none">
        <div className="intro-text" style={{ marginTop: "0px" }}>
          <Image
            src="../img/Header-Mobile.png"
            rounded
            style={{ maxWidth: "100%" }}
          />
          <h1>{props.data ? props.data.title : "Loading"}</h1>
          <p>{props.data ? props.data.paragraph : "Loading"}</p>
          <a
            href="https://api.leadconnectorhq.com/widget/booking/FxOIklfU0LO96Gtjcw8S"
            target="_blank"
            className="btn btn-primary btn-lg page-scroll"
          >
            Book Your Strategy Call
          </a>
        </div>
      </div>
      <div className="intro d-none d-sm-block">
        <Parallax bgImage={image1} strength={200}>
          <Container>
            <Row>
              <Col md={{ span: 7, offset: 5 }}>
                <div className="intro-text">
                  <h1>{props.data ? props.data.title : "Loading"}</h1>
                  <p>{props.data ? props.data.paragraph : "Loading"}</p>
                  <a
                    href="https://api.leadconnectorhq.com/widget/booking/FxOIklfU0LO96Gtjcw8S"
                    target="_blank"
                    className="btn btn-primary btn-lg page-scroll"
                  >
                    Book Your Strategy Call
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </Parallax>
      </div>
    </header>
  );
};
