import { AnimationOnScroll } from "react-animation-on-scroll";

export const Process = (props) => {
  return (
    <div id="process" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Our Process</h2>
        </div>
        {/* <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
                  {" "}
                  <h3 className="process-title">{d.title}</h3>
                  <i className={d.icon}></i>
                </div>
              ))
            : "Loading..."}
        </div> */}
      </div>
      <div className="container">
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-xs-12 col-md-6">
                  <AnimationOnScroll
                    animateIn="animate__fadeInRight"
                    animateOnce="true"
                  >
                    <h2>{d.title}</h2>
                    <i className={d.icon}></i>
                    <div className="process-desc">
                      <p>{d.text}</p>
                    </div>
                  </AnimationOnScroll>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
