
import { HeaderGeneral } from "../../Components/HeaderGeneral";
import { Process } from "../../Components/Process";
import { Services } from "../../Components/Services";
import { Quote } from "./../../Components/Quote";
import "./OurValue.css"; 

const OurValue = (props) => {
    return (
      <div>
      <HeaderGeneral data={props.data.HeaderOurValue} />
      <Process data={props.data.ProcessSteps} />
      <Quote data={props.data.Quote1} />
      <Services data={props.data.Services} />
      <Quote data={props.data.Quote3} />
      </div>
    )
  }

  
  export default OurValue;
  